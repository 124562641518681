<template>
  <div class="addpro">
    <el-form
      ref="form"
      label-width="130px"
      label-position="right"
      :model="form"
      :rules="ruleForm"
    >
      <el-form-item label="所属系统:" prop="systemId">
        <el-select v-model="form.systemId" clearable placeholder="系统名称"
          ><el-option
            v-for="item in sysList"
            :value="item.systemId"
            :key="item.systemId"
            :label="item.systemName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品名称:" prop="productName">
        <el-input v-model="form.productName" class="public-input" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="产品价格:" prop="productPrice">
        <el-input v-model="form.productPrice" class="public-input" type="number" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="产品Logo:" prop="productLogo">
        <el-upload action="" :auto-upload="false" :show-file-list="false" accept="image/*" :on-change="upChange">
          <img  class="logo" :src="form.productLogo" alt="" v-if="form.productLogo">
          <el-button type="" v-else>选择图片</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="产品描述:">
         <quill-editor
          v-model="form.productText"
          ref="myQuillEditor"
          :options="editorOption"
          @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
          @change="onEditorChange($event)">
        </quill-editor>
      </el-form-item>
      <el-form-item>
         <myButton v-if="$route.query.type == 20" title="提交" @myclick="update" />
        <myButton title="提交" v-else @myclick="submit" />
      </el-form-item>
    </el-form>
     <input type="file" id="upImg" accept="image/*" multiple style="visibility: hidden;top: -10000px"/>
  </div>
</template>
<script>
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import {quillEditor, Quill} from 'vue-quill-editor'

    const Delta = Quill.import('delta')
    let bz = 0;

    const toolbarOptions = [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],
        [{header: 1}, {header: 2}], // custom button values
        [{list: "ordered"}, {list: "bullet"}],
        [{script: "sub"}, {script: "super"}], // superscript/subscript
        [{indent: "-1"}, {indent: "+1"}], // outdent/indent
        [{direction: "rtl"}], // text direction
        [{size: ["small", false, "large", "huge"]}], // custom dropdown
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        [{color: []}],
        [{background: []}],
        [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        [{font: []}],
        [{align: []}],
        ["link", "image"],
        ["clean"], // remove formatting button
    ];
export default {
    components: {
            quillEditor
        },
  data() {
    return {
      form: {
        systemId: "",
        productName: "",
        productPrice: "",
        productText: "",
        productLogo:''
      },
      editorOption:{},
      ruleForm: {
        systemId: [
          { required: true, message: "请选择所属系统", trigger: "change" },
        ],
        productName: [
          { required: true, message: "请输入产品名称", trigger: "blur" },
        ],
        productPrice: [
          { required: true, message: "请输入产品价格", trigger: "blur" },
        ],
      },
      total: 0,
      show_type: [],
      sysList: [],
      appUrl:''
    };
  },
  created() {
      this.appUrl = process.env.VUE_APP_URL;
     const _this = this;
            this.goodsId = this.$route.query.goodsId
            this.editorOption = {
                modules: {
                    toolbar: {
                        container: toolbarOptions, // 工具栏
                        handlers: {
                            // quill插件实例
                            image: function (value) {
                                // quill插件实例
                                let _self = this
                                // 创建上传文件input并触发
                                let fileInput = document.getElementById('upImg')

                                fileInput.addEventListener('change', () => {
                                    console.log("bz", fileInput.files.length)
                                    if (bz >= 1 || fileInput.files.length == 0) {
                                        return false
                                    }
                                    bz++;
                                    //获取选中图片对象（包含文件的名称、大小、类型等，如file.size）
                                    var files = fileInput.files;   //document.querySelector('input[type=file]').files[0];
                                    // files= Array.from(files).reverse()
                                    console.log(files)
                                    let i = 1

                                    function IMGUP(data) {
                                        if (data == null) {
                                            return false
                                        }
                                        const range = _self.quill.getSelection(true)
                                        // 获取光标所在位置
                                        let length = _self.quill.getSelection().index;
                                        let formData = new FormData();
                                        formData.append("file", data);
                                        formData.append("group_id", 0);
                                        formData.append("noSave", 0);
                                        _this.$request.HttpPost("/admin/upload/upload", formData,).then(res => {
                                            if (res.code == 0) {
                                                const url = _this.appUrl + '/'+res.data.file.url
                                                //插入到文本
                                                _self.quill.updateContents(
                                                    new Delta()
                                                        .retain(range.index)
                                                        .delete(range.length)
                                                        .insert({image: url})
                                                )
                                                // 调整光标到最后
                                                _self.quill.setSelection(length + 1);
                                                //插入到文本
                                                _self.quill.updateContents(
                                                    new Delta()
                                                        .retain(range.index)
                                                        .delete(range.length)
                                                        .insert('\n')
                                                )
                                            }
                                            if (i < files.length) {
                                                IMGUP(files[i++])
                                                console.log(i)
                                            } else {

                                            }
                                        })


                                    }

                                    IMGUP(files[0])
                                })
                                fileInput.click()
                                bz = 0
                            }

                        }
                    }
                }
            };
    this.getSystemList();
    if(this.$route.query.id != 'undefined'){
        this.getDetail(this.$route.query.id)
    }
  },
  methods: {
    getSystemList() {
      this.$request.HttpGet("/admin/cmsSystem/list").then((res) => {
        this.sysList = res.data.list;
        console.log(res);
      });
    },
    getDetail(id){
       this.$request.HttpGet('/admin/cmsProduct/find',{id})
       .then(res => {
           if(res.data){
               this.form = res.data;
               this.form.systemId = res.data.systemInfo.systemId
           }
           
       })
    },
    submit() {
      this.$refs.form.validate((res) => {
        if (res) {
          this.$request
            .HttpPost("/admin/cmsProduct/create", this.form)
            .then((res) => {
                this.$plugin.notifySuccess(res.msg)
                this.$router.back()
              console.log(res);
            });
        }
      });
    },
    update(){
         this.$refs.form.validate((res) => {
        if (res) {
          this.$request
            .HttpPost("/admin/cmsProduct/update", this.form)
            .then((res) => {
                this.$plugin.notifySuccess(res.msg)
                this.$router.back()
              console.log(res);
            });
        }
      });
    },
    onEditorFocus(){},
    onEditorChange(){},
    onEditorBlur(){},
    upChange(file){
      let obj = new FormData();
      obj.append('file',file.raw)
       this.$request.HttpPost('/admin/upload/upload',obj)
       .then(res =>{
         this.form.productLogo = this.appUrl + '/' + res.data.file.url;
       })
    }
  },
};
</script>
<style lang='scss'>
 .logo{
   width: 100px;
   height: 100px;
 }
</style>